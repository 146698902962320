import React, { useEffect, useState } from "react";
import "./Header.css";

/* IMAGES */
import Logo from "../../assets/img/logo.png";
import Topbar from "./Topbar";
import ProfileImg from "../../assets/img/user-dashboard/profile.png";
import { NavLink, useLocation, useRouteLoaderData } from "react-router-dom";
import {
  getAuthToken,
  getLocalStorage,
  logout,
  tokenLoader,
} from "../../utils/auth";
import { normalPages, userPages } from "../../utils/pageList";

export default function Header() {
  // const [token,setToken] = useState()
  const { pathname } = useLocation();

  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState();
  const [isAuthSection, setIsAuthSection] = useState(false);
  const [isUserSection, setIsUserSection] = useState(false);
  const [isNormalSection, setIsNormalSection] = useState(false);
  const [mobileView, setMobileView] = useState();
  useEffect(() => {
    tokenLoader();
    setToken(getAuthToken());
    if (pathname == "/auth/login" || pathname == "/auth/register") {
      setIsAuthSection(true);
    } else {
      setIsAuthSection(false);
    }
    if (userPages.includes(pathname)) {
      setIsUserSection(true);
    } else {
      setIsUserSection(false);
    }
    if (normalPages.includes(pathname)) {
      setIsNormalSection(true);
    } else {
      setIsNormalSection(false);
    }
  }, [pathname,mobileView]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 110 ? true : false);
      if (window.outerWidth < 801) {
        setScrolled(true);
      }
    };

    const handleResize = () => {
      setScrolled(window.outerWidth < 801);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.outerWidth < 801) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [window.outerWidth]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const logoutHandler = () => {
    logout();
  };

  return (
    <header className={`header ${scrolled? 'sticky-top':''}`}>
      <div className="d-flex">
        <a href="/" className="p-0 d-flex align-items-center">
        <img
          src={Logo}
          alt="Falcon Logo"
          className="logo-icon mx-auto img-fluid"
          style={{ width: scrolled ? "70px" : "120px" }}
        />
        </a>
        <div className="left-side-bar flex-grow-1">
          {!scrolled && (
            <div className="top-bar">
              <Topbar />
            </div>
          )}
          <nav className="navbar">
            <ul className={`nav-links ${isOpen ? "mobile-view" : ""}`}>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Home
                </NavLink>
              </li>
              {[
                (!mobileView && isUserSection) ||
                  (mobileView && isAuthSection)||
                  (mobileView && isNormalSection) 
              ] && (
                <>
                  <li>
                    <NavLink
                      to="/about"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/plan"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Plans & Benefits
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sip"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      What is SIP?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </>
              )}
              {!token && (
                <li>
                  {!isAuthSection && (
                    <NavLink to="/auth/login" className="btn login__btn">
                      Login
                    </NavLink>
                  )}
                </li>
              )}
              {token && !mobileView &&  (
                <li>
                  {/* <button  className="btn login__btn" onClick={logoutHandler}>Logout</button> */}
                  {!isUserSection&&<div className="menu-container">
                    <div className="menu-trigger">
                      <div className="img-containter">
                        <img src={ProfileImg} alt="profile " />
                        <span className="d-block ">My Account</span>

                      </div>
                    </div>
                    <div className="profile-dropdown">
                      <ul>
                        <DropdownItem text="Dashboard" to="/user/dashboard" />
                        <DropdownItem
                          text="Logout"
                          onClick={() => {
                            logout();
                          }}
                        />
                      </ul>
                    </div>
                  </div>}
                </li>
              )}
              {token && mobileView && (
                <>
                  <li>
                    <NavLink
                      to="/user/dashboard"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  {isUserSection && (
                    <>
                      <li>
                        <NavLink
                          to="/user/investment"
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                        >
                          Investment
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/user/claim"
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                        >
                          Claim
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/user/payment-history"
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                        >
                          Payment History
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/user/profile"
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                        >
                          Profile
                        </NavLink>
                      </li>
                    </>
                  )}
                  <li>
                    <button
                      onClick={() => logout()}
                      className="btn text-white  py-0"
                    >
                      Logout
                    </button>
                  </li>
                </>
              )}
            </ul>
            <button
              id="hamburger-menu"
              className="hamburger-menu-button"
              type="button"
              onClick={handleToggle}
            >
              <span
                className={
                  isOpen
                    ? "hamburger-menu-button-open hamburger-menu-button-close"
                    : "hamburger-menu-button-open"
                }
              ></span>
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
}

function DropdownItem({ text, to, onClick }) {
  return (
    <>
      <li className="dropdownItem">
        <NavLink to={to} className="dropdown-item" onClick={onClick}>
          {text}
        </NavLink>
      </li>
    </>
  );
}
