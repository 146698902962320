import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import searchIcon from "../../assets/img/user-dashboard/shield-search.svg";
import "./Investment.css";
import { getCall, LoggedpostCall } from "../../services/APICall";
import InvestCard from "../../UI/Card/InvestCard/InvestCard";
import Input from "../../UI/Input/Input";
import { useForm } from "react-hook-form";
import FormBtn from "../../UI/Button/FormBtn/FormBtn";
import ToastComponent from "../../UI/Toast/Toast";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from 'primereact/multiselect';

export default function Investment() {
  const navigate = useNavigate();
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const [investList, setInvestList] = useState([]);
  const [toastData, setToastData] = useState({ show: false });
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // New state to track search input
  
  const statusList = [
    { name: 'Active', code: '1' },
    { name: 'Settled', code: '0' },
  ];

  const [loanDialogData, setLoanDialogData] = useState({
    show: false,
    requestLoading: false,
    data: null,
  });

  useEffect(() => {
    getInvestList();
  }, []);

  const getInvestList = async () => {
    const { response, result } = await getCall("investment-list");
    if (response.ok) {
      setInvestList(result?.data || []);
      setFilteredList(result?.data || []); // Initialize filteredList with all data
    }
  };

  const takeLoanHandler = async (investment) => {
    const payload = { investment_id: investment.id };
    const { response, result } = await LoggedpostCall("loan-limit", JSON.stringify(payload), false);

    if (result?.data?.loan_eligibility) {
      setLoanDialogData({
        show: true,
        requestLoading: false,
        data: { ...result.data, investment_id: investment.id, inv_code: investment.inv_code },
      });
    }
  };

  const loanRequestHandler = async (formData) => {
    setLoanDialogData((prev) => ({ ...prev, requestLoading: true }));

    const payload = {
      ...formData,
      investment_id: loanDialogData?.data?.investment_id,
      loan_type: "installment",
      _comment: "loan_type:installment,fullpayment",
    };

    const { response, result } = await LoggedpostCall("loan-request", JSON.stringify(payload), false);

    setToastData({
      show: true,
      type: result.status,
      message: result?.message,
      title: result.status === "error" ? "Error" : "Success",
    });

    setLoanDialogData({ show: false, requestLoading: false, data: null });
    reset();

    setTimeout(() => {
      setToastData({ show: false });
      if (result.status !== "error") navigate('/user/loan');
    }, 5000);
  };

  // Filter investment list based on selected status and search query
  useEffect(() => {
    let filtered = investList;

    // Filter by status if any status is selected
    if (selectedStatus.length > 0) {
      filtered = filtered.filter((investment) =>
        selectedStatus.some((status) => Number(status.code) === Number(investment.is_active))
      );
    }

    // Filter by search query (invest code)
    if (searchQuery) {
      filtered = filtered.filter((investment) =>
        investment.inv_code.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredList(filtered);
  }, [selectedStatus, searchQuery, investList]);

  return (
    <>
      <ToastComponent
        className={toastData.type === "error" ? "error-toast" : "success-toast"}
        show={toastData?.show}
        type={toastData.type}
        message={toastData.message}
        title={toastData.title}
      />

      <div className="user-info-head d-flex justify-content-between">
        <div className="user-info-head__content-container">
          <div className="head">Investment</div>
          <div className="content">Manage your investment & renewals</div>
        </div>

        <div className="input-container order-3 order-lg-2 ms-auto me-lg-5 my-3 d-flex justify-content-end gap-2">
          <div className="search-container form-control">
            <img src={searchIcon} alt="search" />
            <input
              type="text"
              placeholder="Search " // Updated placeholder text
              className="search"
              name="search"
              id="search"
              value={searchQuery} // Bind input to searchQuery state
              onChange={(e) => setSearchQuery(e.target.value)} // Update search query state on input
            />
          </div>

          <div className="dropdown status-select form-control">
            <MultiSelect 
              value={selectedStatus} 
              onChange={(e) => setSelectedStatus(e.value)} 
              options={statusList} 
              optionLabel="name"
              placeholder="Select Status"
              maxSelectedLabels={3} 
              className="w-full md:w-20rem" 
              style={{ width: "150px" }} 
            />
          </div>
        </div>
      </div>

      {Array.isArray(filteredList) && filteredList.length > 0 ? (
        filteredList.map((list) => (
          <InvestCard key={list.id} list={list} takeLoanHandler={() => takeLoanHandler(list)} />
        ))
      ) : (
        <div className="fw-bold text-secondary fs-4 text-center my-2">No Investment data available</div>
      )}

      <Dialog
        header=""
        visible={loanDialogData?.show}
        modal
        style={{ width: "450px" }}
        draggable={false}
        resizable={false}
        className="p-2 bg-white"
        onHide={() => setLoanDialogData({ show: false, requestLoading: false, data: null })}
      >
        {loanDialogData.data && (
          <div className="px-3">
            <div className="dialog-head">
              <span className="fs-2 text-center d-flex justify-content-center fw-bold gap-1">
                <span className="blue-head">Loan</span>
                <span className="red-head">Request</span>
              </span>
            </div>
            <div className="dialog-body my-2">
              <div className="dialog-body__info mx-auto fw-bold" style={{ maxWidth: "380px" }}>
                <div className="row">
                  <div className="col-6">Investment ID</div>
                  <div className="col-6 d-flex gap-1">
                    <span>: </span>
                    <span className="primary-head">{loanDialogData?.data?.inv_code}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">Eligible Amount</div>
                  <div className="col-6 d-flex gap-1">
                    <span>: </span>
                    <span className="primary-head">{loanDialogData?.data?.eligibility_amount}</span>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit(loanRequestHandler)} className="m-3">
                <Input
                  placeholder="₹ 0.00"
                  id="amount"
                  label="Loan Amount"
                  type="number"
                  register={register}
                  validation={{
                    required: "Loan Amount is required",
                    valueAsNumber: true,
                    min: { value: 0, message: "Amount cannot be negative" },
                    max: {
                      value: Number(loanDialogData?.data?.eligibility_amount),
                      message: `Amount cannot exceed ₹${loanDialogData?.data?.eligibility_amount}`,
                    },
                  }}
                  errors={errors}
                />
                <Input
                  placeholder="Reason for Loan"
                  id="reason"
                  label="Reason"
                  register={register}
                  validation={{ required: "Reason is required" }}
                  errors={errors}
                />
                <Input
                  placeholder="Select Duration"
                  id="loan_duration"
                  type="select"
                  label="Loan Duration"
                  register={register}
                  validation={{ required: "Duration is required" }}
                  errors={errors}
                  options={[
                    { id: 0, value: "6 Months", label: "6 Months" },
                    { id: 1, value: "1 Year", label: "1 Year" },
                  ]}
                />
                <div className="text-center my-4">
                  <FormBtn
                    type="submit"
                    isLoading={loanDialogData.requestLoading}
                    className="btn red__btn w-50"
                  >
                    Submit
                  </FormBtn>
                </div>
              </form>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}
