import Select from "react-select";
import "./Home.css";
import Carousel from "react-bootstrap/Carousel";

import carouselBg1 from "../../assets/img/home/1.jpg";
import carouselBg2 from "../../assets/img/home/2.jpg";
import carouselBg3 from "../../assets/img/home/3.jpg";
import carouselBg4 from "../../assets/img/home/4.jpg";

import CalculatorIcon from "../../assets/img/home/calculator.svg";
import DremHomeImg from "../../assets/img/home/dream-home-icon.svg";
import HomImg from "../../assets/img/home/home.png";
import RightArrow from "../../assets/img/home/right-arrow.svg";
import LineImg from "../..//assets/img/home/line.svg";
import No1n from "../../assets/img/home/no1-icon.svg";
import No2n from "../../assets/img/home/no2-icon.svg";
import No3n from "../../assets/img/home/no3-icon.svg";

import HomeImg from "../../assets/img/home/home-image.svg";

import TickIcon from "../../assets/img/home/tick.svg";
import WalletIcon from "../../assets/img/home/wallet.svg";
import RupeeIcon from "../../assets/img/home/rupee.svg";
import ClockIcon from "../../assets/img/home/clock.svg";
import Carousels from "../../components/Header/carousel";
import PlanCardCarousel from "../../UI/Carousel/PlanCardCarousel/PlanCardCarousel";
import { getAuthToken } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useRef, useState, useEffect } from "react";
import { getCall, LoggedpostCall } from "../../services/APICall";
import ToastComponent from "../../UI/Toast/Toast";
import { optimizeList } from "../../utils/optimize";
import { useDispatch } from "react-redux";
import { addUserData } from "../../slicer/dummyUserSlicer";
import { reset, toggleTableView } from "../../slicer/sipSlicer";

export default function Home() {
   // useDispatch()
   
   const dispatch = useDispatch();
   useEffect(() => {
     dispatch(reset());
   }, []);
  const token = getAuthToken();
  const navigate = useNavigate();
  const formContainerRef = useRef();
  const [divHeight, setDivHeight] = useState(0);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [toastData, setToastData] = useState({ show: false });
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    heightHandler();
  }, [errors, isSubmitSuccessful]);

  useEffect(() => {
    getStateList();
  }, []);

  const getStateList = async () => {
    const { response, result } = await getCall("state-list");
    if (response?.ok) {
      // setStateList(result.data);
      setStateList(optimizeList(result.data, "state_code", "state_name"));
    } else {
      setToastData({
        show: true,
        title: "Error",
        type: "error",
        message: result?.message,
        className: "error-toast",
      });
    }
  };

  const getCityList = async (state_code) => {
    const payload = { state_code };
    const { response, result } = await LoggedpostCall(
      "city-list",
      JSON.stringify(payload),
      false
    );
    if (response?.ok) {
      // setStateList(result.data);
      setCityList(optimizeList(result.data, "city_code", "city_name"));
    } else {
      setToastData({
        show: true,
        title: "Error",
        type: "error",
        message: result?.message,
        className: "error-toast",
      });
    }
  };

  const selectedState = watch("state");

  useEffect(() => {
    if (selectedState) {
      getCityList(selectedState.value);
    }
  }, [selectedState]);

  const heightHandler = () => {
    if (formContainerRef.current) {
      setDivHeight(formContainerRef.current.offsetHeight);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const payload = {
      ...data,
      state: data.state.value,
      city: data.city.value,
    };

    dispatch(
      addUserData({
        haveUser: true,
        userDetails: payload,
      })
    );

    const { result, response } = await LoggedpostCall(
      "customer-enquiry",
      JSON.stringify(payload),
      false
    );
    setLoading(false);
    navigate('/sip-calculation')
    // Handle form submission logic
  };

  /* GET  */

  return (
    <>
      <ToastComponent
        show={toastData?.show}
        title={toastData?.title}
        message={toastData?.message}
        className={toastData?.className}
        type={toastData?.type}
      />
      {/* <!-- START TOP CONTAINER --> */}
      <section
        className="top-container"
        style={{ height: `${Number(divHeight) + 100}px` }}
      >
        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-bs-ride="carousel"
          style={{ height: token ? "auto" : `${Number(divHeight) + 100}px` }}
        >
          <div
            className="carousel-inner"
            style={{ height: token ? "auto" : `${Number(divHeight) + 100}px` }}
          >
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100 "
                  src={carouselBg1}
                  alt="First slide"
                  style={{
                    height: token ? "auto" : `${Number(divHeight) + 100}px`,
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={carouselBg2}
                  alt="Second slide"
                  style={{
                    height: token ? "auto" : `${Number(divHeight) + 100}px`,
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={carouselBg3}
                  alt="Third slide"
                  style={{
                    height: token ? "auto" : `${Number(divHeight) + 100}px`,
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={carouselBg4}
                  alt="Fourth slide"
                  style={{
                    height: token ? "auto" : `${Number(divHeight) + 100}px`,
                  }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="top-container__sub py-5">
          <div className="container">
            <div
              className="row gap-3 flex-wrap flex-md-nowrap align-items-center"
              ref={formContainerRef}
            >
              <div
                className="col-md-6 top-container__left"
                ref={formContainerRef}
              >
                <p className="welcome">Welcome</p>
                <p className="head_content mt-2">
                  Invest In Your Future With Falcon Asset Management Unit
                </p>
                <p>
                  Embark on a journey to financial growth with our tailored
                  Systematic Investment Plans. Secure your tomorrow, today.
                </p>
                {/* <button className="btn primary__btn view_more_detail_btn hvr-shutter-out-vertical" onClick={()=>{
                  if(token){
                    navigate('/user/dashboard');
                  }else{
                    navigate('/sip-calculation');

                  }
                  }}>
                  View More Details
                </button> */}
              </div>
              {!token && (
                <div className="col-md-6">
                  <div className="invest-plan__form__container mx-auto p-4">
                    <p className="text-center">
                      Plans starting from ₹5,000/month
                    </p>
                    {/* <!-- START PLANNING FORM --> */}
                    <form
                      className="invest-plan__form pt-1 p-3 needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="mb-3 input-container">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          {...register("name", {
                            required: "Please enter your name",
                          })}
                          placeholder="Your Name"
                        />
                        {errors.name && (
                          <div className="invalid-feedback">
                            {errors.name.message}
                          </div>
                        )}
                      </div>

                      <div className="mb-3 input-container">
                        <input
                          type="number"
                          className={`form-control ${
                            errors.contact ? "is-invalid" : ""
                          }`}
                          {...register("contact", {
                            required: "Please enter your Mobile number",
                            pattern: {
                              value: /^[6789]\d{9}$/,
                              message: "Provide Valid Contact Number",
                            },
                          })}
                          placeholder="Mobile Number"
                        />
                        {errors.contact && (
                          <div className="invalid-feedback">
                            {errors.contact.message}
                          </div>
                        )}
                      </div>

                      <div className="mb-3 input-container">
                        <input
                          type="email"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          {...register("email", {
                            required: "Please enter a valid email address",
                            pattern: {
                              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                              message: "Please enter a valid email address",
                            },
                          })}
                          placeholder="Email Id"
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email.message}
                          </div>
                        )}
                      </div>

                      {/* <div className="d-flex flex-column  flex-md-row gap-2 gap-y-0 mb-1"> */}
                      <div className="mb-3 input-container">
                        <div className="d-flex flex-column">
                          <input
                            type="date"
                            className={`form-control ${
                              errors.dob ? "is-invalid" : ""
                            }`}
                            {...register("dob", {
                              required: "Please enter your date of birth",
                              max: {
                                value: new Date().toISOString().split("T")[0], // Set today's date as max
                                message: "Future dates are not allowed",
                              },
                            })}
                            max={new Date().toISOString().split("T")[0]} // Restrict future dates in date picker
                            placeholder="D.O.B"
                          />
                          {errors.dob && (
                            <div className="invalid-feedback">
                              {errors.dob.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mb-3 input-container d-flex flex-column w-100">
                        <select
                          className={`form-select ${
                            errors.gender ? "is-invalid" : ""
                          }`}
                          {...register("gender", {
                            required: "Please select your gender",
                          })}
                        >
                          <option value="">Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Others</option>
                        </select>
                        {errors.gender && (
                          <div className="invalid-feedback">
                            {errors.gender.message}
                          </div>
                        )}
                        {/* </div> */}
                      </div>

                      <div className="mb-3 input-container">
                        <select
                          className={`form-select ${
                            errors.occupation ? "is-invalid" : ""
                          }`}
                          {...register("occupation", {
                            required: "Please select your occupation",
                          })}
                        >
                          <option value="">Occupation</option>
                          <option value="employed">Employed</option>
                          <option value="self-employed">Self-Employed</option>
                          <option value="student">Student</option>
                          <option value="retired">Retired</option>
                        </select>
                        {errors.occupation && (
                          <div className="invalid-feedback">
                            {errors.occupation.message}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <Controller
                          name="state"
                          control={control}
                          rules={{ required: "Please select a State" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={stateList}
                              className={`form-control form-select ${
                                errors.state ? "is-invalid" : ""
                              }`}
                              placeholder="Select a State..."
                            />
                          )}
                        />
                        {errors.state && (
                          <div className="invalid-feedback">
                            {errors.state.message}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <Controller
                          name="city"
                          control={control}
                          rules={{ required: "Please select a City" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={cityList}
                              className={`form-control form-select ${
                                errors.state ? "is-invalid" : ""
                              }`}
                              placeholder="Select a City..."
                            />
                          )}
                        />
                        {errors.state && (
                          <div className="invalid-feedback">
                            {errors.state.message}
                          </div>
                        )}
                      </div>

                      <div className="mb-3 input-container">
                        <button
                          type="submit"
                          className="btn white__btn w-100 rounded-0 d-flex justify-content-between align-items-center hvr-icon-drop"
                          disabled={loading} // Disable the button during loading
                        >
                          {loading ? (
                            <>
                              <span>Processing...</span>
                              <div
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                              />
                            </>
                          ) : (
                            <>
                              <span>Proceed with Investment</span>
                              <img
                                src={CalculatorIcon}
                                alt="calculator"
                                style={{ height: "24px" }}
                                className="hvr-icon"
                              />
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                    {/* <!-- END PLANNING FORM --> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END TOP CONTAINER --> */}

      {/* <!-- START RECOMENDED CONTAINER --> */}
      <div className="recommend-container pt-5">
        <div className="container">
          <h4 className="red-head text-center">Recommended For</h4>
          <h3 className="blue-head fw-bold text-center fs-2">
            Goal based SIP Investing
          </h3>
          <div className="owl-carousel mx-auto pt-3">
            <PlanCardCarousel />
          </div>
        </div>
      </div>
      {/* <!-- END RECOMENDED CONTAINER --> */}

      {/* <!-- START  THIRD SECTION --> */}
      <div className="thired-section mt-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div>
                <p className="fs-2 fw-bold">
                  Get there faster with <br />
                  <sapn className="red-head fs-2"> 12% </sapn> Higher
                  <span className="red-head fs-2"> Returns </span>
                </p>
                <p className="text__light">
                  A Falcon Asset Management Unit is a firm which ensures the
                  asset Management of an individual investors or an institution
                  or both. Our Management Unit manages the portfolio of an
                  individual ensuring them to enlighten in different phase of
                  life through our SIP plan.
                </p>
                <button className="btn primary__btn view_more_detail_btn hvr-shutter-out-vertical" onClick={()=>{navigate('/plan')}}>
                  Explore Now
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={HomeImg}
                alt="home image"
                className="img-fluid"
                style={{ width: "90%" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- END  THIRD SECTION --> */}
      <div className="card-section my-5">
        <div className="container">
          <div className="row gy-3 gap-lg-0 justify-content-lg-between justify-content-center">
            <div className="col-md-4 col-lg-3 col-6 ">
              <div className="base-card card">
                <div className="base-card__icon">
                  <img src={TickIcon} alt="tick" />
                </div>
                <div className="base-card__content">
                  Earn Higher <br /> Returns
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-6 ">
              <div className="base-card card">
                <div className="base-card__icon">
                  <img src={WalletIcon} alt="Wallet" />
                </div>
                <div className="base-card__content">
                  Capital <br /> Appreciation
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-6">
              <div className="base-card card">
                <div className="base-card__icon">
                  <img
                    src={RupeeIcon}
                    alt=" Automate Your
                Investing"
                  />
                </div>
                <div className="base-card__content">
                  Automate Your <br /> Investing
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-6">
              <div className="base-card card">
                <div className="base-card__icon">
                  <img
                    src={ClockIcon}
                    alt=" Automate Your
                Investing"
                  />
                </div>
                <div className="base-card__content">
                  Safe and Secure <br /> Investments
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- JOURNEY CONTAINER --> */}
      <div className="journey-container my-5">
        <div className="container p-5 ">
          <h6 className="fs-4 text-center blue-head">
            How To Start Your Investing Journey On The Falcon?
          </h6>
          <p className="red-head text-center py-2">
            At The Falcon, we follow 3 easy steps to make mutual fund
            investments simple, convenient, and quick:
          </p>

          <div className="journy-block-container p-md-5 mx-md-5 mx-1 text-center">
            <img
              src={LineImg}
              className="img-fluid w-100 line-img"
              alt="line"
            />
            <img
              src={No1n}
              alt="one"
              className="journey-block block-1 img-fluid"
            />
            <img
              src={No2n}
              alt="two"
              className="journey-block block-2 img-fluid"
            />
            <img
              src={No3n}
              alt="three"
              className="journey-block block-3 img-fluid"
            />
          </div>
        </div>
      </div>
    </>
  );
}
