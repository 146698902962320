import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import './Modal.css';
const ReusableModal = ({ show, handleClose, title, children, footer,style }) => (
  <Modal show={show} onHide={handleClose}  >
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      {footer && (
        <>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </>
      )}
    </Modal.Footer>
  </Modal>
);

ReusableModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

ReusableModal.defaultProps = {
  footer: null,
};

export default memo(ReusableModal);
