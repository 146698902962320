export function convertDateFormat(dateStr) {
    // Check if dateStr is valid and not empty
    if (!dateStr) return null;
    
    // Extract the date part from the input string
    let datePart = dateStr.split(' ')[0];
  
    // Split the date part by "/"
    let parts = datePart.split('-');
  
    // Ensure the date parts are correctly split
    if (parts.length !== 3) return null;
  
    // Rearrange the parts to form yyyy-mm-dd
    let formattedDate = `${parts[0]}/${parts[1]}/${parts[2]}`;
  
    return formattedDate;
}

  