import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";

import { Paginator } from "primereact/paginator";

import { getCall } from "../../services/APICall";
import "./PaymentHistory.css";

import searchIcon from "../../assets/img/user-dashboard/shield-search.svg";
import UserTable from "../../UI/Table/UserTable/UserTable";
import SuccessIcon from "../../assets/img/user-dashboard/success.png";

export default function PaymentHistory() {
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    getPaymentHistory();
  }, []);

  const getPaymentHistory = async (page = 1) => {
    setLoading(true);
    const { response, result } = await getCall(`payment-list?page=${page}`);
    setLoading(false);

    if (response?.status === 200) {
      setPaymentList(result?.data?.data || []);
      setCount(result?.count || 0);
    } else {
      setPaymentList([]);
      setCount(0);
    }
  };

  const renderStatusBtn = ({ status }) => (
    <Button
      label={status === 1 ? "Successful" : "Failed"}
      className={`p-1 px-2 rounded padge-btn  ${
        status === 1 ? "padge-success" : "p-button-secondary"
      }`}
    />
  );

  const tableData = [
    { field: "investment_code", header: "Investment ID" },
    { field: "payment_date", header: "Date", className: "text-nowrap" },
    { field: "transaction_id", header: "Transaction ID" },
    { field: "order_id", header: "No. of Payment" },
    { field: "amount", header: "Transaction Amount" },
    { field: renderStatusBtn, header: "Status" },
  ];

  const onPageChange = (event) => {
    const page = event.page + 1;
    getPaymentHistory(page);
  };

  return (
    <>
      <div className="user-info-head  d-flex flex-column flex-lg-row justify-content-lg-between">
        <div className="user-info-head__content-container text-start w-100">
          <div className="head">Payment History</div>
          <div className="content">Manage your investment & renewals</div>
        </div>
        <div className="input-container w-100 order-3 order-lg-2 ms-auto me-lg-5 my-3 d-flex justify-content-between justify-content-lg-end gap-2">
          <div className="search-container form-control">
            <img src={searchIcon} alt="search" />
            <input
              type="text"
              placeholder="Search"
              className="search"
              name="search"
              id="search"
            />
          </div>

          <div className="dropdown status-select form-control">
          {/* <MultiSelect 
              value={selectedStatus} 
              onChange={(e) => setSelectedStatus(e.value)} 
              options={statusList} 
              optionLabel="name"
              placeholder="Select Status"
              maxSelectedLabels={3} 
              className="w-full md:w-20rem" 
              style={{ width: "150px" }} 
            /> */}
          </div>
        </div>
      </div>
      <div className="payment-list-contianer">
        <div className="dashboard-view">
          <UserTable
            loading={loading}
            totalRecords={count}
            rows={rows}
            columns={tableData}
            data={paymentList}
            onPageChange={onPageChange}
          />
        </div>
        <div className="mobile-view">
          <div className="row accordion-list-head w-100 mx-auto">
            <div className="col-6">Investment ID</div>
            <div className="col-2">Date</div>
            <div className="col-2">Transaction Amount</div>
            <div className="col-2">Status</div>
          </div>
          <Accordion activeIndex={0}>
            {paymentList.map((list) => (
              <AccordionTab
                header={
                  <span className="row align-items-center ">
                    <span className="col-6">{list?.investment_code}</span>
                    <span className="col-2 text-nowrap">
                      {list?.payment_date}
                    </span>
                    <span className="col-2 text-center">{list?.amount}</span>
                    <span className="col-2 ">
                      {list?.status == 1 && (
                        <img
                          src={SuccessIcon}
                          alt="Success Icon"
                          className="img-fluid"
                        />
                      )}
                    </span>
                  </span>
                }
              >
                <div>
                  <div className="row p-2">
                    <div className="col-4 d-flex flex-column">
                      <span className="primary-head fw-bold">
                        Transaction ID
                      </span>
                      <span className="blue-head">{list?.transaction_id}</span>
                    </div>
                    <div className="col-4 d-flex flex-column">
                      <span className="primary-head fw-bold">
                        No. of Payment
                      </span>
                      <span className="blue-head">04</span>
                    </div>
                    <div className="col-4">
                      <button className="btn blue__btn">
                        PDF <i class="bi bi-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            ))}
          </Accordion>
          <Paginator
            first={1}
            rows={rows}
            totalRecords={count}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
}
