import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";

import "./Contact.css";
import { postCall } from "../../services/APICall";
import ToastComponent from "../../UI/Toast/Toast";
import { useState } from "react";
export default function Contact() {
  const [toastData,setToastData]=useState({show:false})
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors,isSubmitting  },
  } = useForm();

  const onSubmit = async(data) => {
    const {result,response}=await postCall('quick-enquiry',data);
    reset();
    // show,
    // title,
    // message,
    // onClose,
    // className,
    // type = "error",
      setToastData({
        show:true,
        title:result.status=="error"?"Error":"Success",
        message:result.status=="error"?result.error:result.message,
        className:result.status=="error"?"error-toast":"success-toast",
        type:result.status
        

      })

    // Handle form submission logic here
  };

  return (
    <>
    <ToastComponent 
     show={toastData.show}
     title={toastData.title}
     message={toastData.message}
     className={toastData.className}
     type={toastData.status}
     autohide={3000}
    />
      {/* <!-- head-container --> */}
      <div className="top-head-container">
        <div className="container top-head-sub-container">
          <h2 className="head">Contact Us</h2>
        </div>
        <div className="page-info">
          <NavLink to="/">Home </NavLink>/ Contact Us
        </div>
      </div>
      {/* <!-- START CONTACT CONTAINER --> */}
      <section className="contact__container red-light-bg">
        <div className="container py-5 pb-1">
          <h6 className="red-head text-center fs-5 fw-bold mb-3">Contact Us</h6>
          <h6 className="fs-4 fs-md-2 blue-head text-center fw-bold mb-3">
            Contact us to learn more about The Falcon
          </h6>
          <div className="row pt-3">
            <div className="col-md-6">
              <h6 className="fs-4 fs-md-2 blue-head fw-bold mb-3">
                Additional <span className="red-head">Information</span>
              </h6>
              <p>
                Please contact us for additional information about The Falcon
                and the solutions we can deliver for you
              </p>

              <div className="mb-3">
                <p className="red-head">Let’s Talk</p>
                <p>+91 9876543012</p>
              </div>
              <div className="mb-3">
                <p className="red-head">Investors</p>
                <p>investors@thefalcon.com</p>
              </div>
              <div className="mb-3">
                <p className="red-head">General Inquiries</p>
                <p>info@thefalcon.com</p>
              </div>
            </div>
            <div className="col-md-6">
              <h6 className=" fs-4 fs-md-2 blue-head fw-bold mb-3">
                Quick <span className="red-head">Enquiry</span>
              </h6>
              <form onSubmit={handleSubmit(onSubmit)} className="contact__form">
                <div className="row gap-1 flex-md-nowrap mb-3">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">First Name*</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      {...register("name", {
                        required: "First Name is required",
                      })}
                    />
                    {errors.name && (
                      <p style={{ color: "red" }}>{errors.name.message}</p>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      className="form-control"
                      id="lastname"
                      {...register("lastname")}
                    />
                  </div>
                </div>
                <div className="row gap-1 flex-md-nowrap mb-3">
                  <div className="form-group col-md-6">
                    <label htmlFor="exampleInputEmail1">Email*</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email.message}</p>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="contact">Contact Number *</label>
                    <input
                      type="number"
                      name="contact"
                      className="form-control"
                      id="contact"
                      {...register("contact", {
                        required: "Contact Number is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Invalid contact number",
                        },
                      })}
                    />
                    {errors.contact && (
                      <p style={{ color: "red" }}>{errors.contact.message}</p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="details">Details</label>
                  <textarea
                    name="details"
                    className="form-control"
                    id="details"
                    {...register("details")}
                  ></textarea>
                </div>

                <button 
        type="submit" 
        className="btn red__btn my-2 shutter_btn hvr-shutter-out-vertical"
        disabled={isSubmitting} // Disable button while submitting
      >
        {isSubmitting ? 'Submitting...' : 'Submit'} {/* Change button text based on isSubmitting */}
      </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- START CONTACT CONTAINER --> */}
    </>
  );
}
