import "./SideNavbar.css";
import { NavLink } from "react-router-dom";
import DashboardIcon from "../../assets/img/side-navbar/dashboard.svg";
import DashboardIconActive from "../../assets/img/side-navbar/dashboard-active.svg";
import InvestmentIcon from "../../assets/img/side-navbar/investment.svg";
import InvestmentIconActive from "../../assets/img/side-navbar/investment-active.svg";
import ClaimIcon from "../../assets/img/side-navbar/claim.svg";
import ClaimIconActive from "../../assets/img/side-navbar/claim-active.svg";
import PaymentIcon from "../../assets/img/side-navbar/payment.svg";
import PaymentIconActive from "../../assets/img/side-navbar/payment-active.svg";
import ProfileIcon from "../../assets/img/side-navbar/profile.svg";
import ProfileIconActive from "../../assets/img/side-navbar/profile-active.svg";

const MenuItem = ({ to, label, icon, iconActive }) => (
  <li className="menu-item">
    <NavLink to={to} className="menu-link gap-2">
      {({ isActive }) => (
        <>
          <div className="text-nowrap">{label}</div>
          <img src={iconActive } alt={`${label} Icon`} className ="dashboard-icon-active" />
          <img src={icon} alt={`${label} Icon`} className ="dashboard-icon"/>
        </>
      )}
    </NavLink>
  </li>
);

export default function SideNavbar() {
  const menuList = [
    {
      id: 1,
      to: "/user/dashboard",
      label: "Dashboard",
      icon: DashboardIcon,
      activeIcon: DashboardIconActive,
    },
    {
      id: 2,
      to: "/user/investment",
      label: "Investment",
      icon: InvestmentIcon,
      activeIcon: InvestmentIconActive,
    },
    // {
    //   id: 3,
    //   to: "/user/claim",
    //   label: "Claim",
    //   icon: ClaimIcon,
    //   activeIcon: ClaimIconActive,
    // },
    {
      id: 3,
      to: "/user/loan",
      label: "Loan",
      icon: ClaimIcon,
      activeIcon: ClaimIconActive,
    },
    {
      id: 4,
      to: "/user/payment-history",
      label: "Payment History",
      icon: PaymentIcon,
      activeIcon: PaymentIconActive,
    },
    {
      id: 5,
      to: "/user/profile",
      label: "Profile",
      icon: ProfileIcon,
      activeIcon: ProfileIconActive,
    },
  ];
  return (
    <aside className="side-nav-bar me-2 h-100">
      <ul className="menu-inner py-1">
        {menuList.map(list=>(
          <MenuItem
            to={list.to}
            label={list.label}
            icon={list.icon}
            iconActive={list.activeIcon}
          />

        ))}
        
      </ul>
    </aside>
  );
}
