import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import "./Profile.css";
import ProfileInput from "../../UI/Input/ProfileInput";
import { getCall, LoggedpostCall } from "../../services/APICall";
import { valueAssign } from "../../utils/values";
import ProfileIcon from "../../assets/img/user-dashboard/profile.svg";
import ProfileIconActive from "../../assets/img/user-dashboard/profile-active.svg";
import LockIcon from "../../assets/img/user-dashboard/lock.svg";
import { optimizeList } from "../../utils/optimize";
import Select from "react-select";

export default function Profile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [accountFormChanged, setAccountFormChanged] = useState(false);
  const [idFormChanged, setIdFormChanged] = useState(false);
  const [contactFormChanged, setContactFormChanged] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [toastData, setToastData] = useState({ show: false });
  const [cityList, setCityList] = useState([]);
  const [editMode, setEditMode] = useState({
    personal: false,
    account: false,
    idProof: false,
    contact: false,
  });

  const methods = useForm({
    defaultValues: {
      name: "",
      lastname: "",
      marital_status: "",
      dob: "",
      gender: "",
      education: "",
    },
  });
  const accountMethods = useForm({
    defaultValues: {
      contact: "",
      email: "",
    },
  });
  const idProofMethods = useForm({
    defaultValues: {
      aadhar: "",
      pan: "",
    },
  });
  const contactMethods = useForm({
    defaultValues: {
      door_no: "",
      area: "",
      street: "",
      state: "",
      city:"",
      pincode: "",
    },
  });

  const formatDateToInput = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  };

  const getUserProfile = useCallback(async () => {
    try {
      const { response, result } = await getCall("user-profile");
      if (response.ok) {
        if (result.data?.dob) {
          result.data.dob = formatDateToInput(result.data?.dob);
        }
        setUserData(result.data);
        const optimizedData = valueAssign(methods.getValues(), result.data);
        const optimizedAccountData = valueAssign(
          accountMethods.getValues(),
          result.data
        );
        const optimizedIdProofData = valueAssign(
          idProofMethods.getValues(),
          result.data
        );
        const optimizedContactData = valueAssign(
          contactMethods.getValues(),
          result.data
        );
        methods.reset(optimizedData);
        accountMethods.reset(optimizedAccountData);
        idProofMethods.reset(optimizedIdProofData);
        contactMethods.reset(optimizedContactData);  // Ensure this is being called

      } else {
        setError("Failed to fetch profile data.");
      }
    } catch (error) {
      setError("An error occurred while fetching profile data.");
    } finally {
      setLoading(false);
    }
  }, [methods]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  // Track form changes
  useEffect(() => {
    const subscription = methods.watch((currentValues) => {
      const isFormChanged = Object.keys(userData).some(
        (key) => userData[key] !== currentValues[key]
      );
      setFormChanged(isFormChanged);
    });

    return () => subscription.unsubscribe();
  }, [methods, userData]);
  useEffect(() => {
    const subscription = accountMethods.watch((currentValues) => {
      const isFormChanged = Object.keys(userData).some(
        (key) => userData[key] !== currentValues[key]
      );
      setAccountFormChanged(isFormChanged);
    });

    return () => subscription.unsubscribe();
  }, [accountMethods, userData]);
  useEffect(() => {
    const subscription = idProofMethods.watch((currentValues) => {
      const isFormChanged = Object.keys(userData).some(
        (key) => userData[key] !== currentValues[key]
      );
      setIdFormChanged(isFormChanged);
    });

    return () => subscription.unsubscribe();
  }, [idProofMethods, userData]);
  useEffect(() => {
    const subscription = contactMethods.watch((currentValues) => {
      const isFormChanged = Object.keys(userData).some(
        (key) => userData[key] !== currentValues[key]
      );
      setContactFormChanged(isFormChanged);
    });
    return () => subscription.unsubscribe();
  }, [contactMethods, userData]);

  const handleEditMode = (section, value) => {
    setEditMode((prevState) => ({
      ...prevState,
      [section]: value,
    }));
  };

  const onSubmit = async (data) => {
    setFormChanged(false); // Reset form change status after submission
    setUserData(data); // Update user data after saving
    const payload = {
      ...userData,
      ...data,
    };
    const result = await LoggedpostCall("user-profile-update", JSON.stringify(payload),false);
  };
  const accountOnSubmit = async (data) => {
    setFormChanged(false); // Reset form change status after submission
    setUserData(data); // Update user data after saving
    const payload = {
      ...userData,
      ...data,
    };
    const result = await LoggedpostCall("user-profile-update", JSON.stringify(payload),false);
  };
  const contactOnSubmit = async (data) => {
    setFormChanged(false); // Reset form change status after submission
    setUserData(data); // Update user data after saving
    const payload = {
      ...userData,
      ...data,
      state:data.state.value,
      city:data.city.value
    };
    const result = await LoggedpostCall("user-profile-update", JSON.stringify(payload),false);
  };

  useEffect(() => {
    getStateList();
  }, []);

  const getStateList = async () => {
    const { response, result } = await getCall("state-list");
    if (response?.ok) {
      // setStateList(result.data);
      setStateList(optimizeList(result.data, "state_code", "state_name"));
    } else {
      setToastData({
        show: true,
        title: "Error",
        type: "error",
        message: result?.message,
        className: "error-toast",
      });
    }
  };
  

  const getCityList = async (state_code) => {
    const payload = { state_code };
    const { response, result } = await LoggedpostCall(
      "city-list",
      JSON.stringify(payload),
      false
    );
    if (response?.ok) {
      // setStateList(result.data);
      setCityList(optimizeList(result.data, "city_code", "city_name"));
    } else {
      setToastData({
        show: true,
        title: "Error",
        type: "error",
        message: result?.message,
        className: "error-toast",
      });
    }
  };

  const selectedState = contactMethods.watch("state");
  useEffect(() => {
    if (selectedState) {
      getCityList(selectedState.value);
    }
  }, [selectedState]);


  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div className="user-info-head d-flex justify-content-between gap-3 gap-md-0 flex-wrap flex-md-nowrap">
        <div className="user-info-head__content-container">
          <div className="head">Profile</div>
          <div className="content text-nowrap">
            Manage your personal & contact details
          </div>
        </div>
        <div className="user-info-head__tab-container d-flex w-100 justify-content-md-end">
          <button
            className="btn active"
            onClick={() => navigate("/user/profile")}
          >
            <img src={ProfileIconActive} alt="Profile" />
            <span>Profile</span>
          </button>
          <button className="btn" onClick={() => navigate("/user/password")}>
            <img src={LockIcon} alt="Change Password" />
            Change Password
          </button>
        </div>
      </div>

      <div className="profile-container">
        <div className="card">
          <div className="card-head">
            <p>Personal details</p>
          </div>
          <div className="card-body">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <section className="input-section">
                  <div className="section-head">
                    <span className="head">Personal</span>
                    <button
                      className="btn red__btn"
                      type="button"
                      onClick={() => handleEditMode("personal", true)}
                    >
                      Edit Info
                    </button>
                  </div>
                  <div className="section-body">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="First Name"
                          name="name"
                          validationRules={{
                            required: "First name is required",
                          }}
                          disabled={!editMode.personal}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Last Name"
                          name="lastname"
                          validationRules={{
                            required: "Last name is required",
                          }}
                          disabled={!editMode.personal}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Gender"
                          name="gender"
                          inputType="select"
                          options={[
                            { value: "male", label: "Male" },
                            { value: "female", label: "Female" },
                            { value: "others", label: "Others" },
                          ]}
                          validationRules={{ required: "Gender is required" }}
                          disabled={!editMode.personal}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Date of Birth"
                          name="dob"
                          type="date"
                          validationRules={{
                            required: "Date of Birth is required",
                          }}
                          disabled={!editMode.personal}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Marital Status"
                          name="marital_status"
                          inputType="select"
                          options={[
                            { value: "married", label: "Married" },
                            { value: "single", label: "Single" },
                            { value: "others", label: "Others" },
                          ]}
                          validationRules={{
                            required: "Marital Status is required",
                          }}
                          disabled={!editMode.personal}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Education"
                          name="education"
                          validationRules={{
                            required: "Education is required",
                          }}
                          disabled={!editMode.personal}
                        />
                      </div>
                    </div>
                    {formChanged && (
                      <button type="submit" className="btn btn-primary mt-3">
                        Save
                      </button>
                    )}
                  </div>
                </section>
              </form>
            </FormProvider>
            <FormProvider {...accountMethods}>
              <form onSubmit={accountMethods.handleSubmit(accountOnSubmit)}>
                <section className="input-section">
                  <div className="section-head">
                    <span className="head">Account</span>
                    <button
                      className="btn red__btn"
                      type="button"
                      onClick={() => handleEditMode("account", true)}
                    >
                      Edit Info
                    </button>
                  </div>
                  <div className="section-body">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Email ID"
                          name="email"
                          validationRules={{ required: "Email ID is required" }}
                          disabled={!editMode.account}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Mobile Number"
                          name="contact"
                          validationRules={{
                            required: "Mobile Number is required",
                          }}
                          disabled={!editMode.account}
                        />
                      </div>
                    </div>
                    {accountFormChanged && (
                      <button type="submit" className="btn btn-primary mt-3">
                        Save
                      </button>
                    )}
                  </div>
                </section>
              </form>
            </FormProvider>
            <FormProvider {...idProofMethods}>
              <form onSubmit={idProofMethods.handleSubmit(accountOnSubmit)}>
                <section className="input-section">
                  <div className="section-head">
                    <span className="head">ID Proof</span>
                    <button
                      className="btn red__btn"
                      type="button"
                      onClick={() => handleEditMode("idProof", true)}
                    >
                      Edit Info
                    </button>
                  </div>
                  <div className="section-body">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Aadhar"
                          name="aadhar"
                          validationRules={{ required: "Aadhaar is required" }}
                          disabled={!editMode.idProof}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="PAN"
                          name="pan"
                          validationRules={{
                            required: "PAN is required",
                          }}
                          disabled={!editMode.idProof}
                        />
                      </div>
                    </div>
                    {idFormChanged && (
                      <button type="submit" className="btn btn-primary mt-3">
                        Save
                      </button>
                    )}
                  </div>
                </section>
              </form>
            </FormProvider>
            <FormProvider {...contactMethods}>
              <form onSubmit={contactMethods.handleSubmit(contactOnSubmit)}>
                <section className="input-section">
                  <div className="section-head">
                    <span className="head">Contact</span>
                    <button
                      className="btn red__btn"
                      type="button"
                      onClick={() => handleEditMode("contact", true)}
                    >
                      Edit Info
                    </button>
                  </div>
                  <div className="section-body">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Door/Apartment No."
                          name="door_no"
                          validationRules={{
                            required: "Door/Apartment No. is required",
                          }}
                          disabled={!editMode.contact}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Area/Landmark"
                          name="area"
                          validationRules={{
                            required: "Area is required",
                          }}
                          disabled={!editMode.contact}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Street/Road"
                          name="street"
                          validationRules={{
                            required: "Street/Road is required",
                          }}
                          disabled={!editMode.contact}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        
                        <ProfileInput
                          label="State"
                          name="state"
                          inputType="select"
                          validationRules={{ required: "State is required" }}
                          disabled={!editMode.contact}
                          options={stateList}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                       
                        <ProfileInput
                          label="City"
                          name="city"
                          inputType="select"
                          validationRules={{ required: "city is required" }}
                          disabled={!editMode.contact}
                          options={cityList}
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <ProfileInput
                          label="Pincode"
                          name="pincode"
                          validationRules={{ required: "pincode is required" }}
                          disabled={!editMode.contact}
                        />
                      </div>
                    </div>
                    {contactFormChanged && (
                      <button type="submit" className="btn btn-primary mt-3">
                        Save
                      </button>
                    )}
                  </div>
                </section>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
}
