import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';

const ProfileInput = ({ label, name, type = "text", disabled, inputType = 'input', validationRules, options = [], ...rest }) => {
  const { control,register, formState: { errors } } = useFormContext();

  return (
    <div className="input-container">
      <label htmlFor={name}>{label}</label>

      {inputType === "select" ? (
        <Controller
          name={name}
          control={control}
          rules={validationRules}
          render={({ field }) => (
            <Select
              {...field}
              id={name}
              options={options}
              isDisabled={disabled}
              className={errors[name] ? "input-error" : ""}
              classNamePrefix="react-select"
              isSearchable
              {...rest}
            />
          )}
        />
      ) : (
        <input
          id={name}
          type={type}
          {...register(name, validationRules)}
          {...rest}
          className={errors[name] ? "input-error" : ""}
          disabled={disabled}
        />
      )}

      {errors[name] && <span className="error-message">{errors[name].message}</span>}
    </div>
  );
};

export default ProfileInput;
